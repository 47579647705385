import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridColDef, GridSelectionModel} from '@mui/x-data-grid';
import {Catalogue} from "../../../models/CatalogueEntryModel";
import {Fab} from "@mui/material";
import {Print} from "@mui/icons-material";

const columns: GridColDef[] = [
    {
        field: 'title',
        headerName: 'Titre de l\'ouvrage',
        width: 400,
        editable: false,
    },
    {
        field: 'authors',
        headerName: 'Auteur(s)',
        width: 200,
        editable: false,
    },
    {
        field: 'publication',
        headerName: 'Publication',
        width: 400,
        editable: false,
    },
    {
        field: 'keywords',
        headerName: 'Mots clés',
        width: 600,
        editable: false,
    },
    {
        field: 'cote',
        headerName: 'Cote',
        width: 200,
        editable: false,
    },
];

const LibraryTable = (params: { catalogue: Catalogue; }) => {
    const {catalogue} = params;
    const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);

    const handleSelectionChange = (newSelection: GridSelectionModel) => {
        setSelectedRows(newSelection);
        if (newSelection.length > 0) {
            setShowPrint(true)
        } else {
            setShowPrint(false)
        }
    };

    const rows = catalogue.Catalogue;
    const [showPrint, setShowPrint] = useState(false);

    function generateHtmlTable<T extends Record<string, any>>(dataArray: T[]): string {
        if (!Array.isArray(dataArray) || dataArray.length === 0) {
            console.error("Invalid data array or empty array.");
            return '';
        }

        // Extract the headers from the first object
        const headers = Object.keys(dataArray[0]);

        // Create the table element
        let tableHtml = '<table style="border: 1px; border-collapse: collapse; width: 100%;">';

        // Add table headers
        tableHtml += '<thead><tr>';
        headers.forEach(header => {
            tableHtml += `<th style="padding: 8px; text-align: left;">${header.charAt(0).toUpperCase() + header.slice(1)}</th>`;
        });
        tableHtml += '</tr></thead>';

        // Add table rows
        tableHtml += '<tbody>';
        dataArray.forEach(row => {
            tableHtml += '<tr>';
            headers.forEach(header => {
                tableHtml += `<td style="padding: 8px;">${row[header]}</td>`;
            });
            tableHtml += '</tr>';
        });
        tableHtml += '</tbody>';

        // Close the table
        tableHtml += '</table>';

        return tableHtml;
    }

    const print = () => {
        const printWindow = window.open('', '_blank');

        if (!printWindow) {
            console.error('Failed to open a new window for printing.');
            return;
        }

        const selectedTable = rows
            .filter(row => selectedRows.includes(row.id))
            .map(row => {
                return {
                    title: row.title ?? "",
                    authors: row.authors ?? "",
                    publication: row.publication ?? "",
                    cote: row.cote ?? "",
                }
            })

        printWindow.document.write(`
            <!DOCTYPE html>
            <html lang="fr-FR">
            <head>
              <title>Print Table</title>
              <style>
                table {
                  width: 100%;
                  border-collapse: collapse;
                }
                table, th, td {
                  border: 1px solid black;
                }
                th, td {
                  padding: 8px;
                  text-align: left;
                }
              </style>
            </head>
            <body>
              <h1>ITEAG Library</h1>
              ${generateHtmlTable(selectedTable)}
            </body>
            </html>
          `);

        printWindow.document.close();

        printWindow.print();
        printWindow.close();
    }

    return (
        <Box sx={{height: 631, width: '100%'}}>
            <DataGrid
                rows={rows}
                columns={columns}
                onSelectionModelChange={handleSelectionChange}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
                experimentalFeatures={{ newEditingApi: true }}
            />
            {showPrint && <Box>
                {/*Desktop*/}
              <Box sx={{display: {xs: "none", md: "block"}}} position={"fixed"} bottom={30} left={30}
                   onClick={print} zIndex={100}>
                <Fab size={"medium"}>
                  <Print/>
                </Fab>
              </Box>

                {/*Mobile*/}
              <Box sx={{display: {xs: "block", md: "none"}}} position={"fixed"} bottom={8} left={8}
                   onClick={print} zIndex={100}>
                <Fab size={"small"}>
                  <Print/>
                </Fab>
              </Box>
            </Box>}
        </Box>
    );
}

export default LibraryTable;
