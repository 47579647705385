import {Box} from "@mui/material";
import {Catalogue} from "../../models/CatalogueEntryModel";
import catalogueJson from "../../models/catalogue.json";
import LibraryTable from "./components/LibraryTable";

const LibraryPage = () => {
    const generateId = (index: number): number => {
        return index + 1;
    };

    const extractKeywords = (inputString: string): string => {
        if (!inputString) return "";
        const components = inputString.split(/\s|-|\|/);
        const exclusionList = new Set(["DE", "DU", "DES", "LA", "LE", "LES"])
        return components
            .filter(word => /^[a-zA-Z]+$/.test(word) && word.length > 1)
            .map(word => word.toUpperCase())
            .filter(word => !exclusionList.has(word)).join(" ");
    }

    const transformToCatalogue = (catalogueJson: any): Catalogue => {
        return {
            Catalogue: catalogueJson.Catalogue.map((entry: any, index: number) => ({
                id: generateId(index),
                status: entry.Statut ?? null,
                title: entry["Titre de l'ouvrage"] ?? "",
                authors: entry["Auteur(s)"] ?? "",
                cote: entry["Cote "] ?? "",
                publication: entry.Publication ?? null,
                keywords: extractKeywords(entry["Mots clés"]),
                number: entry.Exemplaires ?? null,
            })),
        };
    }

    const catalogue = transformToCatalogue(catalogueJson);

    return (
        <Box p={2}>
            <LibraryTable catalogue={catalogue}/>
        </Box>
    );
}

export default LibraryPage;