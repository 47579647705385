import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {Link, useNavigate} from "react-router-dom";
import SchoolIcon from '@mui/icons-material/School';
import InfoIcon from '@mui/icons-material/Info';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import {NavbarLink} from "./components/NavbarLink";
import {AppBar, Typography} from "@mui/material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import EmailIcon from '@mui/icons-material/Email';

const pages = [
    {label: "Actualités", href: "/news", icon: <NewspaperIcon/>},
    {label: "Formations", href: "/education", icon: <SchoolIcon/>},
    {label: "Informations", href: "/information", icon: <InfoIcon/>},
    {label: "Bibliothèque", href: "/library", icon: <LocalLibraryIcon/>},
];

export const Navbar = () => {
    const navigate = useNavigate();

    const zoomMargin = 100;
    const logoWidthMin = 80;
    const logoWidthMax = 150;
    const [logoWidth, setLogoWidth] = useState(window.scrollY < zoomMargin ? logoWidthMax : logoWidthMin);
    const [isZoomedIn, setIsZoomedIn] = useState(window.scrollY < zoomMargin);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    useEffect(() => {
        const handleOnScroll = () => {
            if (isZoomedIn && window.scrollY > zoomMargin) {
                setLogoWidth(logoWidthMin);
                setIsZoomedIn(false);
            }
            if (!isZoomedIn && window.scrollY === 0) {
                setLogoWidth(logoWidthMax);
                setIsZoomedIn(true);
            }
        }

        window.addEventListener("scroll", handleOnScroll);
        return () => window.removeEventListener("scroll", handleOnScroll);
    }, [isZoomedIn])

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleClickNavMenu = (href: string) => {
        setAnchorElNav(null);
        navigate(href);
    }

    return (
        <>
            <Box sx={{
                display: "flex",
                bgcolor: "primary.dark",
                color: "primary.contrastText",
                opacity: 0.96,
                zIndex: 100,
            }}>
                <Box ml={"auto"} mr={"auto"} display={"flex"}>
                    <Box display={"flex"}>
                        <Box fontSize={"1rem"} color={"primary.light"} mr={1}>
                            <LocalPhoneIcon fontSize={"inherit"}/>
                        </Box>
                        <a href={"tel:+590 690 37 64 17"} style={{textDecoration: "none"}}>
                            <Typography variant={"body2"} color={"primary.light"}>
                                +590 690 37 64 17
                            </Typography>
                        </a>
                    </Box>
                    <Box ml={2} mr={2}>
                        <Typography variant={"body2"} color={"primary.light"}>
                            |
                        </Typography>
                    </Box>
                    <Box display={"flex"}>
                        <Box fontSize={"1rem"} color={"primary.light"} mr={1}>
                            <EmailIcon fontSize={"inherit"}/>
                        </Box>
                        <a href={"mailto:secretariat@iteag.org"} style={{textDecoration: "none"}}>
                            <Typography variant={"body2"} color={"primary.light"}>
                                secretariat@iteag.org
                            </Typography>
                        </a>
                    </Box>
                </Box>
            </Box>
            <AppBar position={"sticky"} sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                bgcolor: "transparent",
                backdropFilter: "blur(6px)",
                zIndex: 100,
            }}
            >
                <Box width={"100%"} height={"100%"} sx={{bgcolor: "primary.main", opacity: .92}}>
                    <Box ml={"auto"} mr={"auto"} maxWidth={"lg"} width={"100%"}>
                        {/*Desktop*/}
                        <Box sx={{display: {xs: "none", md: "flex", flexDirection: "row"}}}>
                            <Box ml={1} mr={2} mt={.6}>
                                <Link to={"/home"}>
                                    <Box
                                        component={"img"}
                                        src="/static/img/logo/logo.png"
                                        width={logoWidth}
                                        sx={{transition: "all .8s ease-in-out 0s"}}
                                    />
                                </Link>
                            </Box>

                            {pages.map((page, index) => (
                                <Box key={index}>
                                    <NavbarLink href={page.href} label={page.label}/>
                                </Box>
                            ))}

                        </Box>

                        {/*Mobile*/}
                        <Box sx={{display: {xs: "flex", md: "none", width: "100vw"}}}>
                            <Box>
                                <IconButton
                                    size="large"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon/>
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: {xs: 'block', md: 'none'},
                                    }}
                                >
                                    {pages.map((page, index) => (
                                        <MenuItem key={index} onClick={() => handleClickNavMenu(page.href)}>
                                            <Button sx={{color: "primary.main"}} variant={"text"}
                                                    startIcon={page.icon}>{page.label}</Button>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>

                            <Box display={"flex"} ml={"auto"} mr={1} mt={"auto"} mb={"auto"}>
                                <Box
                                    onClick={() => navigate("/home")}
                                    ml={1}
                                    component={"img"}
                                    src="/static/img/logo/logo-xs.png"
                                    width={32}
                                    height={28}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </AppBar>
        </>
    );
};